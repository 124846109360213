.Theme {
  background: radial-gradient(circle farthest-corner, rgb(180,165,150), rgb(180,165,150));
  margin: 0;
  padding: 5vmin;
}

@media (prefers-color-scheme: dark) {
  .Theme {
    background: radial-gradient(circle farthest-corner, #405055, transparent),repeating-linear-gradient(-50deg, #211111, #212111 5px, #191911 5px, #111122 10px);
    background-color: rgb(10,10,10);
    margin: 0;
    padding: 5vmin;
  }
}

@media print {
  .Theme {
    background-color: white;
    margin: 0;
    padding: 0;
  }
}

@media screen {
  .Theme {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.Sheet {
  text-align: left;
  margin: 5vmin;
  max-width: 8.5in;
  font-family: DejaVu Sans, sans-serif;
  font-size: calc(12px);
  border-radius: 1vmin;
  margin-bottom: 4vmin;
  padding: 3vmin;
}

.Emoji {
  font-family: 'Noto Emoji';
}

.Theme .Sheet {
  color: rgb(45,45,45);
  border: solid 1px rgb(250,250,250);
  border-left-color: rgb(220,220,220);
  border-top-color: rgb(220,220,220);
  border-right-color: rgb(240,240,240);
  border-bottom-color: rgb(240,240,240);
  background-color: rgb(255,255,255);
}

@media (prefers-color-scheme: dark) {
  .Theme .Sheet {
    color: rgb(200,200,200);
    border: solid 1px rgb(15,35,25);
    background-color: rgb(20,20,20);
  }
}

@media print {
  .Sheet {
    margin: 0;
    padding: 0;
    max-width: none;
    border: none !important;
    font-size: ;
    line-height: 1.1;
  }
}

.Sheet hr {
  margin: 1em;
  margin-left: 33vmin;
  margin-right: 33vmin;
}

.Theme .Sheet hr {
  border-color: rgb(255,180,135);
}

@media (prefers-color-scheme: dark) {
  .Theme .Sheet hr {
    border-color: rgb(45,135,90);
  }
}

@media print {
  .Sheet hr {
    border-color: rgb(200,200,200) !important;
  }
}

.Contact-item {
  white-space: nowrap;
  display: block;
}

.Contact-block {
  margin: 0.125in;
  margin-right: 2em;
  display: inline-block;
}

.Section {
  font-size: calc(12pt);
  color: rgb(45,45,45);
  margin: 1em;
}

.Theme .Section {
  color: rgb(45,45,45);
}

@media (prefers-color-scheme: dark) {
  .Theme .Section {
    color: rgb(200,200,200);
  }
}

@media print {
  .Section {
    font-size: calc(10pt);
    margin: 0.1in;
    color: rgb(0,0,0) !important;
    page-break-inside: avoid;
  }
}

.Section ul li {
  margin-top: 0.5em;
}

@media print {
  .Section ul li {
    margin-top: 0.05in !important;
  }
}

.Theme .Section ul li {
  color: rgb(45,45,45);
}

@media (prefers-color-scheme: dark) {
  .Theme .Section ul li {
    color: rgb(200,200,200);
  }
}

@media print {
  .Section ul li {
    margin-top: 0.1in;
    color: rgb(0,0,0);
  }
}

span.soft {
  font-style: italic;
}

.Section ul li span.soft {
  font-size: calc(11pt);
  font-style: italic;
}

.Theme .Section ul li span.soft {
  color: rgb(145,75,45);
}

@media (prefers-color-scheme: dark) {
  .Theme .Section ul li span.soft {
    color: rgb(45,180,120);
  }
}

@media print {
  .Section ul li span.soft {
    font-size: calc(10pt) !important;
    color: rgb(90,90,90) !important;
  }
}

.Section a {
  text-decoration-line: underline;
}

.Theme .Section a {
  color: rgb(90,90,90);
  text-decoration-color: rgb(0,0,0);
}

@media (prefers-color-scheme: dark) {
  .Theme .Section a {
    color: rgb(200,200,200);
    text-decoration-color: rgb(45,180,120);
  }
}

@media print {
  .Section a {
    color: rgb(90,90,90) !important;
    text-decoration-color: rgb(90,90,90) !important;
  }
}

.Section a:hover {
  text-decoration-line: underline;
}

.Theme .Section a:hover {
  color: rgb(180,90,45);
  text-decoration-color: rgb(0,0,0);
}

@media (prefers-color-scheme: dark) {
  .Theme .Section a:hover {
    color: rgb(45,180,120);
    text-decoration-color: rgb(45,180,120);
  }
}

.Section .Section-title {
  font-weight: bold;
}

.Technologies {
  font-size: calc(12pt);
  border-radius: 1vmin;
  padding: 3vmin;
  padding-top: 2vmin;
  padding-bottom: 2vmin;
  margin: 1em;
}

@media screen {
  .Technologies li {
    margin-bottom: 10pt;
  }
}

.Theme .Technologies {
  color: rgb(45,45,45);
  border-top: solid 1px rgb(245,245,245);
  background: linear-gradient(217deg, rgba(255,165,80,.2), rgba(225,225,225,0) 70.71%),
              linear-gradient(to right, rgba(200,200,200,.5), rgba(200,200,200,0.0) 70.71%),
              linear-gradient(336deg, rgba(0,0,0,.2), rgba(225,225,225,0) 70.71%);
}

@media (prefers-color-scheme: dark) {
  .Theme .Technologies {
    color: rgb(200,200,200);
    border-top: solid 1px rgb(0,0,0);
    background: rgb(0,50,32);
  }
}

@media print {
  .Technologies {
    font-size: calc(10pt);
    border-radius: 0.1in;
    padding: 0.1in;
    padding-top: 0.1in;
    padding-bottom: 0.1in;
    margin: 0.1in;
    color: rgb(0,0,0) !important;
    border: 2px rgb(90,90,90) solid !important;
    background: rgb(255,255,255);
    page-break-inside: avoid;
    line-height: 1.0;
  }
}
