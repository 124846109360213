.Vanity {
  text-align: center;
  background: radial-gradient(circle farthest-corner, #405055, transparent),repeating-linear-gradient(-50deg, #211111, #212111 5px, #191911 5px, #111122 10px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
}

.Vanity-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Vanity-section a {
  margin: calc(10px + 1vmin);
}

.Vanity-img {
  display: inline-block;
  width: 33vmin;
}

.Vanity-link {
  color: #d1eafb;
  display: inline-block;
  text-decoration: none;
  font-size: 14pt;
  margin: 10px;
  padding: 0;
}

.Vanity-link:hover {
  text-decoration-line: underline;
  text-decoration-color: #acf;
}

.Vanity-linklist {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2vmin;
  width: 50vmin;
}

.Vanity-weather {
  font-size: calc(5px + 1vmin);
}

.tip {
  font-size: calc(3px + 1vmin);
  color: #dddddd;
}

.Vanity-err {
  color: #FF8888;
}

.Vanity-spacer {
  height: 10vh;
}
